import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { mdiAlert, mdiAt, mdiCheckCircle, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { doc, updateDoc } from 'firebase/firestore';
import useUsernameInput from '@/hooks/usernameInputHook';
import AuthFormHelp from '@/components/Auth/AuthFormHelp';
import { db } from '@/firebase/firebase';
import { IProfile, IAuthor } from '@/types/Collections/users';

const Dialog = dynamic(() => import('@mui/material/Dialog'), {
  ssr: false,
});

const ProfileUpdateUsername = ({
  onClose,
  open,
  profile,
}: {
  onClose: () => void;
  open: boolean;
  profile: IProfile | IAuthor;
}): JSX.Element => {
  const router = useRouter();

  const [isUpdating, setIsUpdating] = useState(false);

  const {
    username,
    isAvailable: usernameAvailable,
    isChecking: usernameChecking,
    isValid: usernameValid,
    isDirty: usernameDirty,
    showError: usernameShowError,
    bind: bindUsername,
  } = useUsernameInput('');

  const handleClose = () => {
    onClose();
  };
  const slug = router.asPath;

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const url = [...slug?.split('/')];
      const usersOldUsername = decodeURIComponent(url[1]);
      let oldUserName = '';
      if (!(!url.length || usersOldUsername.substring(0, 1) !== '@')) {
        oldUserName = usersOldUsername.substring(1);
      }
      setIsUpdating(true);
      await updateDoc(doc(db, 'users', profile.uid), {
        username,
        usernameLowercase: username?.toLowerCase(),
        usernamePrevious: oldUserName,
      });
      router.push('/[...slug]', `@${username}`);

      handleClose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('[Error]', e);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="profile-options-title">
      <div className="card small-dialog chatroom-modal is-fullwidth max-wdi">
        <div className="card-content">
          <div className="modal-header">
            <h2 id="profile-options-title" className="is-size-4">
              Update Profile
            </h2>
            <div className="buttons modal-close-button">
              <button
                type="button"
                aria-label="Close"
                onClick={() => handleClose()}
                className="button is-light">
                <span className="icon">
                  <Icon path={mdiClose} size={1.25} />
                </span>
              </button>
            </div>
          </div>
          {isUpdating ? (
            <div className="hero-body">
              <div className="container has-text-centered">
                <div className="loading-block" />
              </div>
            </div>
          ) : (
            <form className="auth-form" onSubmit={handleSubmit}>
              <div className="field">
                <label htmlFor="username" className="label">
                  Choose a username <span>*</span>
                </label>
                <p className="help">Your username will be visible to others.</p>
                <div
                  className={`control has-icons-left has-icons-right${
                    usernameChecking ? ' is-loading' : ''
                  }`}>
                  <input
                    id="username"
                    onKeyDown={event => {
                      if (event.code === 'Space') event.preventDefault();
                    }}
                    className={`input is-medium${
                      usernameDirty &&
                      (!usernameValid || !usernameAvailable) &&
                      usernameShowError
                        ? ' is-warning'
                        : ''
                    }${
                      usernameDirty && usernameAvailable && usernameValid
                        ? ' is-success'
                        : ''
                    }`}
                    type="text"
                    placeholder="Username"
                    tabIndex={0}
                    {...bindUsername}
                  />
                  <span className="icon is-small is-left">
                    <Icon path={mdiAt} size={1} />
                  </span>
                  {!usernameChecking &&
                    usernameDirty &&
                    usernameAvailable &&
                    usernameValid && (
                      <span className="icon is-small is-right has-text-success">
                        <Icon path={mdiCheckCircle} size={1} />
                      </span>
                    )}
                  {!usernameChecking &&
                    usernameDirty &&
                    (!usernameValid || !usernameAvailable) &&
                    usernameShowError && (
                      <span className="icon is-small is-right has-text-warning">
                        <Icon path={mdiAlert} size={1} />
                      </span>
                    )}
                </div>

                {!usernameChecking &&
                usernameValid &&
                !usernameAvailable &&
                usernameShowError ? (
                  <AuthFormHelp message="Sorry, this username is not available." />
                ) : (
                  ''
                )}
                {!usernameValid && usernameDirty && usernameShowError ? (
                  <AuthFormHelp message="Please choose a username has a minimum of four characters, a maximum of 15 characters and no symbols or spaces." />
                ) : null}
              </div>
              <div className="form-buttons is-fullwidth">
                <div className="field">
                  <div className="control">
                    <button
                      disabled={
                        usernameChecking ||
                        !usernameAvailable ||
                        username === '' ||
                        !usernameValid ||
                        !usernameDirty
                      }
                      type="submit"
                      className="button is-primary is-medium is-rounded is-fullwidth">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Dialog>
  );
};

ProfileUpdateUsername.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileUpdateUsername;
