import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import useBlock from '@/hooks/useBlock';
import { IAuthor, IProfile } from '@/types/Collections/users';

const ProfileOptionsBlock = ({
  profile,
  onClose,
}: {
  profile: IProfile | IAuthor;
  onClose: () => void;
}): JSX.Element => {
  const { block } = useBlock(profile.uid);

  const handleClose = () => {
    onClose();
  };

  const blockUserConfirm = async () => {
    if (typeof block === 'function') {
      block();
    }
    handleClose();
  };

  return (
    <div className="card confirm-dialog">
      <div className="card-content">
        <p className="mb-4 is-font-brand is-size-4">
          Are you sure you wish to block @{profile.username}?
        </p>
        <div className="buttons modal-footer-buttons">
          <button
            type="button"
            aria-label="Close"
            onClick={() => handleClose()}
            className="button is-light is-medium">
            <span className="icon">
              <Icon path={mdiClose} size={1.25} />
            </span>
          </button>
          <button
            type="button"
            aria-label="OK"
            onClick={() => blockUserConfirm()}
            className="button is-danger is-medium">
            <span className="icon">
              <Icon path={mdiCheck} size={1.25} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileOptionsBlock;
