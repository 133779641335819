import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { serverTimestamp } from 'firebase/firestore';
import { useAuth } from '@/providers/AuthProvider';
import { updateUser } from '@/providers/AuthProvider/hooks/useUser';
import { IProfile, IAuthor } from '@/types/Collections/users';
import { IAuthResponse } from '@/providers/AuthProvider/types';

const ProfileOptionsDelete = ({
  profile,
  onClose,
}: {
  profile: IProfile | IAuthor;
  onClose: () => void;
}): JSX.Element => {
  const [result, setResult] = useState<IAuthResponse | null>(null);
  const { isAdmin, uid } = useAuth();
  const handleClose = () => {
    onClose();
  };

  const deleteUserConfirm = async () => {
    if (isAdmin && uid) {
      const res = await updateUser(profile.uid, {
        isActivated: false,
        isDeleted: true,
        deletedDate: serverTimestamp(),
        deletedBy: uid,
        deletedReason: 'admin-via-web',
      });
      setResult(res);
    }
  };

  if (result) {
    return (
      <div className="card confirm-dialog">
        <div className="card-content">
          <p className="mb-4 is-font-brand is-size-4">
            {result.success ? 'User deleted' : 'Error deleting user'}
          </p>
          <div className="buttons modal-footer-buttons">
            <button
              type="button"
              aria-label="Close"
              onClick={() => handleClose()}
              className="button is-light is-primary is-medium">
              <span className="icon">
                <Icon path={mdiCheck} size={1.25} />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card confirm-dialog">
      <div className="card-content">
        <p className="mb-4 is-font-brand is-size-4">
          Are you sure you wish to delete @{profile.username}?
        </p>
        <div className="buttons modal-footer-buttons">
          <button
            type="button"
            aria-label="Close"
            onClick={() => handleClose()}
            className="button is-light is-medium">
            <span className="icon">
              <Icon path={mdiClose} size={1.25} />
            </span>
          </button>
          <button
            type="button"
            aria-label="OK"
            onClick={() => deleteUserConfirm()}
            className="button is-danger is-medium">
            <span className="icon">
              <Icon path={mdiCheck} size={1.25} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileOptionsDelete;
