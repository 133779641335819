import {
  collection, getDocs, limit, query, where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase/firebase';

interface IUsernameInput {
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  presetUsername: (newValue: string) => void;
  validateUsername: (val: string) => boolean;
  isChecking: boolean;
  isAvailable: boolean;
  isValid: boolean;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  showError: boolean;
  reset: () => void;
  touched: boolean;
  bind: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
}

const useUsernameInput = (initialValue?: string): IUsernameInput => {
  let typingTimeout: number | NodeJS.Timeout = 0;
  const [username, setUsername] = useState<string>(initialValue || '');
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(!!initialValue);
  const [isAvailable, setIsAvailable] = useState<boolean>(!!initialValue);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);

  const validateUsername = (val: string): boolean => {
    const letterNumber = /^\d*[a-z\-_][a-z\-_\d]*$/i; // Amended to ensure that not only numbers are used
    if (val.length < 4 || val.length > 15) {
      return false;
    }
    if (val.match(letterNumber)) {
      return true;
    }
    return false;
  };

  const usernameExists = async (val: string): Promise<boolean | void> => {
    const res = await getDocs(
      query(
        collection(db, 'users'),
        where('usernameLowercase', '==', val.toLowerCase()),
        limit(1),
      ),
    )
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return true;
        }
        return false;
      })
      .catch(() => false);
    return res;
  };

  useEffect(() => {
    if (!isValid || !isAvailable) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [isValid, isAvailable]);

  const handleUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (!touched) {
      setTouched(true);
    }

    const newValue: string = event.currentTarget.value;
    const valid: boolean = validateUsername(newValue);
    setUsername(newValue);
    setIsValid(valid);

    if (newValue !== '') {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }

    if (initialValue === newValue) {
      setIsAvailable(true);
    } else if (valid) {
      typingTimeout = setTimeout(async () => {
        setIsChecking(true);
        if (await usernameExists(newValue)) {
          setIsAvailable(false);
        } else {
          setIsAvailable(true);
        }
        setIsChecking(false);
      }, 500);
    }
  };

  const presetUsername = (newValue: string): void => {
    setUsername(newValue);
    setIsValid(validateUsername(newValue));
  };

  return {
    username,
    setUsername,
    presetUsername,
    validateUsername,
    isChecking,
    isAvailable,
    isValid,
    isDirty,
    setIsDirty,
    showError,
    reset: () => setUsername(''),
    touched,
    bind: {
      value: username,
      onChange: handleUsernameChange,
    },
  };
};
export default useUsernameInput;
