import { mdiDotsHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import ProfileOptionsMenu from './ProfileOptionsMenu';
import ProfileUpdateUsername from './ProfileUpdateUsername';
import { IProfile, IAuthor } from '@/types/Collections/users';

const ProfileOptions = ({
  profile,
}: {
  profile: IProfile | IAuthor;
}): JSX.Element => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isUpdateUserMenuOpen, setIsUpdateMenuOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        aria-label="Options"
        onClick={() => setMenuOpen(true)}
        className="button is-rounded">
        <span className="icon">
          <Icon path={mdiDotsHorizontal} size={1} />
        </span>
      </button>
      <ProfileOptionsMenu
        profile={profile}
        open={isMenuOpen}
        setIsUpdateMenuOpen={setIsUpdateMenuOpen}
        onClose={() => setMenuOpen(false)}
      />
      <ProfileUpdateUsername
        open={isUpdateUserMenuOpen}
        profile={profile}
        onClose={() => setIsUpdateMenuOpen(false)}
      />
    </>
  );
};

export default ProfileOptions;
