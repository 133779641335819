import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useAuth } from '@/providers/AuthProvider';
import ProfileOptionsBlock from './ProfileOptionsBlock';
import ProfileOptionsReport from './ProfileOptionsReport';
import ProfileOptionsDelete from './ProfileOptionsDelete';
import { IProfile, IAuthor } from '@/types/Collections/users';

const Dialog = dynamic(() => import('@mui/material/Dialog'), {
  ssr: false,
});

const ProfileOptionsMenu = ({
  profile,
  onClose,
  open,
  setIsUpdateMenuOpen,
}: {
  profile: IProfile | IAuthor;
  onClose: () => void;
  open: boolean;
  setIsUpdateMenuOpen: (value: boolean) => void;
}): JSX.Element => {
  const { isAdmin } = useAuth();
  const [modalType, setModalType] = useState('default');

  const handleClose = () => {
    onClose();
  };

  const handleExited = () => {
    setModalType('default');
  };

  const handleRepairStreamAccessToken = async () => {
    if (isAdmin) {
      // await repairStreamAccessToken(profile);
    }
  };

  const handleUpdateUserNameModel = () => {
    setIsUpdateMenuOpen(true);
    onClose();
  };

  const content = () => {
    switch (modalType) {
      case 'block':
        return <ProfileOptionsBlock onClose={handleClose} profile={profile} />;

      case 'report':
        return <ProfileOptionsReport onClose={handleClose} profile={profile} />;

      case 'delete':
        return <ProfileOptionsDelete onClose={handleClose} profile={profile} />;

      default:
        return (
          <div className="card small-dialog chatroom-modal">
            <div className="card-content">
              <div className="modal-header">
                <h2 id="profile-options-title" className="is-size-4">
                  Profile options
                </h2>
                <div className="buttons modal-close-button">
                  <button
                    type="button"
                    aria-label="Close"
                    onClick={() => handleClose()}
                    className="button is-light">
                    <span className="icon">
                      <Icon path={mdiClose} size={1.25} />
                    </span>
                  </button>
                </div>
              </div>
              <div className="buttons">
                <button
                  type="button"
                  onClick={() => setModalType('block')}
                  className="button is-danger is-fullwidth is-outlined">
                  Block @{profile.username}
                </button>
                <button
                  type="button"
                  onClick={() => setModalType('report')}
                  className="button is-danger is-fullwidth is-outlined">
                  Report @{profile.username}
                </button>
                {isAdmin ? (
                  <>
                    <Link legacyBehavior href={`/inbox?uid=${profile.uid}`}>
                      <a
                        role="button"
                        tabIndex={0}
                        onClick={handleClose}
                        onKeyDown={handleClose}
                        className="button is-fullwidth">
                        View inbox
                      </a>
                    </Link>
                    <button
                      type="button"
                      onClick={handleRepairStreamAccessToken}
                      className="button is-fullwidth">
                      Repair Stream Token
                    </button>
                    <button
                      type="button"
                      onClick={handleUpdateUserNameModel}
                      className="button is-fullwidth">
                      Update Username
                    </button>
                    <button
                      type="button"
                      onClick={() => setModalType('delete')}
                      className="button is-fullwidth">
                      Delete User
                    </button>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      aria-labelledby="profile-options-title">
      {content()}
    </Dialog>
  );
};

export default ProfileOptionsMenu;
