/*
 * Follow hook using swr-firestore
 * N.B. `data` returns:
 * undefined: when hasn't loaded yet
 * document object: once loaded with data.exists boolean
 * null: when deleted
 */
import { useDocument } from '@nandorojo/swr-firestore';
import {
  collection,
  getDocs,
  query,
  limit,
  updateDoc,
  getDoc,
  doc,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase/firebase';
import * as gtag from '../lib/gtag';
import { useAuth } from '../providers/AuthProvider';

const useSingleFollow = (requestedUid) => {
  const { authLoaded, uid, settings } = useAuth();

  const { data: authIsFollowed, error: isFollowedError } = useDocument(
    requestedUid && uid && requestedUid !== uid
      ? `following/${requestedUid}/userFollowing/${uid}`
      : null,
    {
      listen: false,
    },
  );

  const {
    data: authIsFollowing,
    error: isFollowingError,
    set: setFollowing,
    deleteDocument: deleteFollowing,
    update: updateFollowing,
  } = useDocument(
    requestedUid && uid && requestedUid !== uid
      ? `following/${uid}/userFollowing/${requestedUid}`
      : null,
    {
      listen: false,
    },
  );

  const follow = async () => {
    const updatedAt = new Date();
    await setFollowing(
      {
        created: updatedAt,
      },
      {
        merge: true,
      },
    ).then(async () => {
      if (!settings?.isNpsSeen) {
        getDocs(
          query(collection(db, 'following', uid, 'userFollowing'), limit(4)),
        ).then((querySnapshot) => {
          if (querySnapshot?.docs?.length === 3) {
            updateDoc(doc(db, 'settings', uid), { firstFollowTime: updatedAt });
          }
        });
      }
    });

    gtag.event({
      action: 'user.follow.new',
      params: {
        uid,
        followedUid: requestedUid,
      },
    });
  };

  const updateIntroSent = () => {
    if (authIsFollowing && authIsFollowing.exists) {
      updateFollowing({
        introSent: true,
      });
    }
  };

  const unfollow = async () => {
    await deleteFollowing();
    gtag.event({
      action: 'user.follow.remove',
      params: {
        uid,
        unfollowedUid: requestedUid,
      },
    });
  };

  if (!authLoaded || uid === requestedUid) {
    return {
      status: 'ok',
      error: null,
      updateIntroSent,
    };
  }

  if (isFollowedError) {
    return {
      status: 'error',
      error: isFollowedError,
      updateIntroSent,
    };
  }

  if (isFollowingError) {
    return {
      status: 'error',
      error: isFollowingError,
      updateIntroSent,
    };
  }

  if (
    typeof authIsFollowing === 'undefined'
    || typeof authIsFollowed === 'undefined'
  ) {
    return {
      status: 'loading',
      error: null,
      updateIntroSent,
    };
  }

  if (
    authIsFollowing
    && authIsFollowing.exists
    && authIsFollowed
    && authIsFollowed.exists
  ) {
    return {
      status: 'friend',
      error: null,
      unfollow,
      updateIntroSent,
    };
  }
  if (authIsFollowing && authIsFollowing.exists) {
    return {
      status: 'following',
      introSent: !!authIsFollowing.introSent,
      error: null,
      unfollow,
      updateIntroSent,
    };
  }

  if (authIsFollowed && authIsFollowed.exists) {
    return {
      status: 'followed',
      introSent: !!authIsFollowed.introSent,
      error: null,
      follow,
      updateIntroSent,
    };
  }

  return {
    status: 'ok',
    error: null,
    follow,
    updateIntroSent,
  };
};

const useFollow = (keys) => {
  const { uid } = useAuth();
  const [userId, setUserId] = useState('');
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (keys) {
      if (Array.isArray(keys) && keys.length > 1 && !response) {
        keys.map(async (targetUid) => getDoc(doc(db, 'following', uid, 'userFollowing', targetUid)).then(
          (querySnapshot) => {
            if (querySnapshot.exists()) {
              setResponse((state) => ({ ...state, [targetUid]: true }));
            }
            setResponse((state) => ({ ...state, [targetUid]: false }));
          },
        ));
      } else if (Array.isArray(keys)) {
        setUserId(keys[0]);
      } else {
        setUserId(keys);
      }
    }
  }, [keys, response, setResponse, setUserId, uid]);

  const result = useSingleFollow(userId);

  return response || result;
};

export default useFollow;
