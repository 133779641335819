import { mdiAccount, mdiCheck, mdiEmail } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import useFollow from '@/hooks/followHook';
import { useAuth } from '@/providers/AuthProvider';
import ProfileOptions from './ProfileOptions';
import { IProfile, IAuthor } from '@/types/Collections/users';

const ProfileFollowButton = ({
  profile,
  fullWidth,
  showUnfollow = false,
  showMenu = false,
}: {
  profile: IProfile | IAuthor;
  fullWidth: string;
  showUnfollow?: boolean;
  showMenu?: boolean;
}): JSX.Element | null => {
  const { uid, isAdmin, isVerified, showVerifyEmailDialog } = useAuth();
  const { status, follow, unfollow, introSent } = useFollow(profile.uid);
  if (uid === profile.uid) {
    return null;
  }

  const clickFollow = () => {
    if (typeof follow === 'function') {
      if (isVerified) {
        follow();
      } else {
        showVerifyEmailDialog();
      }
    }
  };

  const clickUnfollow = () => {
    if (typeof unfollow === 'function') {
      if (isVerified) {
        unfollow();
      } else {
        showVerifyEmailDialog();
      }
    }
  };

  let className = `button follow-button is-rounded ${fullWidth}`;
  let buttonText = <span>Follow</span>;
  let buttonIcon = mdiAccount;
  const introMessageButtonText = !introSent ? (
    <span>Send intro</span>
  ) : (
    <span>Intro sent</span>
  );

  if (status === 'loading') {
    className = `${className} is-loading`;
  }

  if (status === 'followed') {
    buttonText = <span>Follow back</span>;
  }

  if (status === 'following' || status === 'friend') {
    className = `${className} is-info`;
    buttonIcon = mdiCheck;
    buttonText = (
      <span className="button-labels">
        <span className="button-label-default">
          {status === 'following' ? 'Following' : 'Friend'}
        </span>
        <span className="button-label-hover">Unfollow</span>
      </span>
    );
  } else {
    className = `${className} is-primary is-outlined`;
  }

  if (status === 'friend' || isAdmin) {
    return (
      <>
        {showUnfollow ? (
          <button
            type="button"
            className={className}
            onClick={status === 'friend' ? clickUnfollow : clickFollow}>
            <span className="icon">
              <Icon path={buttonIcon} size={1} />
            </span>
            {buttonText}
          </button>
        ) : (
          ''
        )}
        <Link
          legacyBehavior
          href="/inbox/new/[...username]"
          as={`/inbox/new/${profile.username}`}>
          <a className="button inbox-button is-rounded is-fullwidth">
            <span className="icon">
              <Icon path={mdiEmail} size={1} />
            </span>
            <span>Message</span>
          </a>
        </Link>
        {showMenu ? <ProfileOptions profile={profile} /> : ''}
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={status === 'following' ? clickUnfollow : clickFollow}>
        <span className="icon">
          <Icon path={buttonIcon} size={1} />
        </span>
        {buttonText}
      </button>

      {status === 'following' && introSent === false && (
        <Link
          legacyBehavior
          href="/inbox/new/[...username]"
          as={`/inbox/new/${profile.username}`}>
          <a className="button inbox-button is-rounded is-fullwidth">
            <span className="icon">
              <Icon path={mdiEmail} size={1} />
            </span>
            {introMessageButtonText}
          </a>
        </Link>
      )}
      {status === 'following' && introSent === true && (
        <span className="button inbox-button is-rounded is-fullwidth is-disabled">
          <span className="icon">
            <Icon path={mdiEmail} size={1} />
          </span>
          {introMessageButtonText}
        </span>
      )}
      {showMenu ? <ProfileOptions profile={profile} /> : ''}
    </>
  );
};

export default ProfileFollowButton;
