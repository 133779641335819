import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { httpsCallable } from 'firebase/functions';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { functions } from '@/firebase/firebase';
import useInput from '@/hooks/inputHook';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';
import { IProfile, IAuthor } from '@/types/Collections/users';

type ReportData = {
  reason: string;
  content: string;
  objectType: string;
  objectId: string;
  objectParentId: string | null;
  objectTitle: string;
  objectAuthor: IAuthor;
};

const ProfileOptionsReport = ({
  profile,
  onClose,
}: {
  profile: IProfile | IAuthor;
  onClose: () => void;
}): JSX.Element => {
  const { uid } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState(false);
  const {
    value: reason,
    bind: bindReason,
    reset: resetReason,
  } = useInput('spam');
  const {
    value: content,
    bind: bindContent,
    reset: resetContent,
  } = useInput('');

  const reportProfile = async (reportData: Partial<ReportData>) => {
    const report = httpsCallable(functions, 'moderationCallable-report');

    const reportObj = { ...reportData };
    reportObj.objectType = 'user';
    reportObj.objectId = profile.uid;
    reportObj.objectParentId = null;
    reportObj.objectTitle = profile.username;
    reportObj.objectAuthor = profile;

    return report(reportObj)
      .then(() => {
        gtag.event({
          action: 'user.report',
          params: {
            uid: profile.uid,
            reporterUid: uid,
            reason: reportObj.reason,
          },
        });
        enqueueSnackbar(
          'Thank you. Your report has been sent to our moderators for review.',
          { variant: 'success' },
        );
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
        return false;
      });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const report = {
      reason,
      content,
    };
    if (reason === 'other' && report.content === '') {
      setError(true);
    } else {
      setError(false);
      reportProfile(report);
      resetReason();
      resetContent();
      handleClose();
    }
  };

  return (
    <div className="card">
      <div className="card-content">
        <h4 className="title is-4">
          Is this profile in breach of Shift.ms house rules?
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label htmlFor="profile-options-report-reason" className="label">
              Please let us know your reasons for reporting this profile
            </label>
            <div className="control">
              <div className="select">
                <select id="profile-options-report-reason" {...bindReason}>
                  <option value="spam">This profile is spam</option>
                  <option value="offensive">This profile is offensive</option>
                  <option value="abusive">This profile is abusive</option>
                  <option value="personal">
                    This profile contains personal contact information
                  </option>
                  <option value="worried">
                    I am worried about this person/member
                  </option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="profile-options-report-content" className="label">
              Please provide any further relevant information
            </label>
            <div className="control">
              <textarea
                id="profile-options-report-content"
                className="textarea"
                placeholder=""
                rows={3}
                {...bindContent}
              />
            </div>
            {error ? (
              <p className="help is-danger">Please enter more information.</p>
            ) : (
              ''
            )}
            <p className="help">
              <a
                href="https://shift.ms/support/house-rules"
                target="_blank"
                rel="noreferrer">
                Read the house rules
              </a>
            </p>
          </div>
          <div className="buttons modal-footer-buttons">
            <button
              type="button"
              aria-label="Close"
              onClick={() => handleClose()}
              className="button is-light is-medium">
              <span className="icon">
                <Icon path={mdiClose} size={1.25} />
              </span>
            </button>
            <button
              type="submit"
              aria-label="OK"
              className="button is-primary is-medium">
              <span className="icon">
                <Icon path={mdiCheck} size={1.25} />
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileOptionsReport;
