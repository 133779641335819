import useSWRImmutable from 'swr/immutable';
import { getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';

type UseBlock = {
  status: 'ok' | 'loading' | 'not-logged-in' | 'blocking' | 'blocked' | 'error';
  error: Error | undefined;
  block?: () => Promise<void>;
  unblock?: () => Promise<void>;
};

const useBlock = (requestedUid: string): UseBlock => {
  const { authLoaded, isLoggedIn, uid } = useAuth();

  const { data: authIsBlocked, error: isBlockedError } = useSWRImmutable(
    authLoaded && isLoggedIn && requestedUid && uid && requestedUid !== uid
      ? `blocking/${requestedUid}/userBlocking/${uid}`
      : null,
    async key => {
      const ref = doc(db, key);
      return getDoc(ref);
    },
  );

  const {
    data: authIsBlocking,
    error: isBlockingError,
    mutate: authIsBlockingMutate,
  } = useSWRImmutable(
    authLoaded && isLoggedIn && requestedUid && uid && requestedUid !== uid
      ? `blocking/${uid}/userBlocking/${requestedUid}`
      : null,
    async key => {
      const ref = doc(db, key);
      return getDoc(ref);
    },
  );

  const block = async () => {
    const updatedAt = new Date();
    const docRef = doc(db, `blocking/${uid}/userBlocking/${requestedUid}`);
    await setDoc(docRef, { created: updatedAt }, { merge: true }).then(() => {
      authIsBlockingMutate();
    });
    gtag.event({
      action: 'user.blocked',
      params: {
        uid,
        blockedUid: requestedUid,
      },
    });
  };

  const unblock = async () => {
    const docRef = doc(db, `blocking/${uid}/userBlocking/${requestedUid}`);
    await deleteDoc(docRef).then(() => {
      authIsBlockingMutate();
    });
    gtag.event({
      action: 'user.unblocked',
      params: {
        uid,
        unblockedUid: requestedUid,
      },
    });
  };

  if (!authLoaded || uid === requestedUid) {
    return {
      status: 'ok',
      error: undefined,
    };
  }

  if (authLoaded && !isLoggedIn) {
    return {
      status: 'not-logged-in',
      error: undefined,
    };
  }

  if (isBlockedError) {
    return {
      status: 'error',
      error: isBlockedError,
    };
  }

  if (isBlockingError) {
    return {
      status: 'error',
      error: isBlockingError,
    };
  }

  if (
    typeof authIsBlocking === 'undefined' ||
    typeof authIsBlocked === 'undefined'
  ) {
    return {
      status: 'loading',
      error: undefined,
    };
  }

  if (authIsBlocking && authIsBlocking.exists()) {
    return {
      status: 'blocking',
      error: undefined,
      unblock,
    };
  }

  if (authIsBlocked && authIsBlocked.exists()) {
    return {
      status: 'blocked',
      error: undefined,
    };
  }

  return {
    status: 'ok',
    error: undefined,
    block,
  };
};

// Currently we only have single block functionality
/*
const useBlock = (keys: string[]): UseBlock => {
  const { uid } = useAuth();
  const [userId, setUserId] = useState('');
  const [response, setResponse] = useState<unknown>(null);

  useEffect(() => {
    if (keys) {
      if (Array.isArray(keys) && keys.length > 1 && !response) {
        const promises = keys.map(async targetUid =>
          getDoc(doc(db, `blocking/${uid}/userBlocking/${targetUid}`)).then(
            querySnapshot => {
              if (querySnapshot.exists()) {
                return true;
              }
              return false;
            },
          ),
        );
        Promise.all(promises).then(() => {
          setResponse(state => ({ ...state, [targetUid]: false }));
        });
      } else if (Array.isArray(keys)) {
        setUserId(keys[0]);
      } else {
        setUserId(keys);
      }
    }
  }, [keys, response, setResponse, setUserId, uid]);
  const result = useSingleBlock(userId);

  return response || result;
}; */

export default useBlock;
